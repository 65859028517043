import React, { useState, useEffect, useMemo, useRef } from "react"
import { QueryClient, QueryClientProvider, useQuery } from "react-query"
import { Link, navigate } from "gatsby"
import { Gender, GetProfile } from "../Utils/ReactQueries"

import {
  MsalAuthenticationTemplate,
  useIsAuthenticated,
  useMsal,
} from "@azure/msal-react"
// import { fetchApi, isNullOrUndefined, patchApi } from "../Common/WebApi-utils"
import { InteractionType, RedirectRequest } from "@azure/msal-browser"
import { loginRequest, msalConfig } from "../Security/authConfig"
import { ErrorComponent } from "../Utils/ErrorComponent"
import { Loading } from "../Utils/Loading"
import { authToken } from "../Security/authToken"
import "react-toastify/dist/ReactToastify.css"
import { useQueryParam, NumberParam, StringParam } from "use-query-params"
import axios from "axios"
import moment from "moment"
import LoadingModal from "../Modal/LoadingModal"
import Seo from "../../components/seo"
import {
  GoToPageButton,
  right,
  saveButton,
  underlineButton,
} from "../Styling/Buttons"

const queryClient = new QueryClient()

const ActivitiesContent = () => {
  const { instance, accounts, inProgress } = useMsal()
  // something like: ?x=123&foo=bar in the URL
  const [orderId, setOrderId] = useQueryParam("o", StringParam)
  const [personId, setPersonId] = useQueryParam("p", StringParam)
  const [type, setType] = useQueryParam("type", StringParam)
  const [status, setStatus] = useQueryParam("status", StringParam)
  const [activity, setActivity] = useState([])
  const [activityReady, setActivityReady] = useState(false)
  const [orderItemReady, setOrderItemReady] = useState(false)
  const userProfile = GetProfile(accounts, inProgress, instance)
  const [activityArray, setActivityArray] = useState(0)
  const [loading, setLoading] = useState(true)

  const GetOrder = (account, inProgress, instance) =>
    useQuery(
      "order",
      async () => {
        const token = await authToken(account, inProgress, instance)
        if (token?.length > 0) {
          return axios
            .get(
              process.env.GATSBY_APP_WEBAPI +
                "HefCeremonies/order/me?personId=" +
                personId +
                "&orderId=" +
                orderId,
              {
                headers: {
                  "Conent-Type": "application/json",
                  "Ocp-Apim-Subscription-Key":
                    process.env.GATSBY_APP_APIM_SUBSCRIPTION_KEY,
                  Authorization:
                    "Bearer " +
                    (await authToken(account, inProgress, instance)),
                },
              }
            )
            .then(res => res.data)
        }
      },
      {
        refetchOnWindowFocus: false,
        retry: (count, error) => {
          if (count >= 3) {
            navigate("/error")
          }
          return true
        },
      }
    )

  const orderItem = GetOrder(accounts, inProgress, instance)

  console.log(orderItem)

  const GetActivities = (account, inProgress, instance) =>
    useQuery(
      "myActivities",
      async () => {
        const token = await authToken(account, inProgress, instance)
        if (token?.length > 0) {
          return axios
            .get(process.env.GATSBY_APP_WEBAPI + "HefCeremonies/me", {
              headers: {
                "Conent-Type": "application/json",
                "Ocp-Apim-Subscription-Key":
                  process.env.GATSBY_APP_APIM_SUBSCRIPTION_KEY,
                Authorization:
                  "Bearer " + (await authToken(account, inProgress, instance)),
              },
            })
            .then(res => res.data)
        }
      },
      {
        refetchOnWindowFocus: false,
        enabled: userProfile.isSuccess,
        retry: (count, error) => {
          if (count >= 3) {
            navigate("/error")
          }
          return true
        },
      }
    )

  const MyActivities = GetActivities(accounts, inProgress, instance)

  //If page is refreshed, wait for activites to get values.
  // useEffect(() => {
  //   if (MyActivities.isSuccess && MyActivities?.data == null) {
  //     MyActivities.refetch()
  //   } else {
  //     setLoading(false)
  //   }
  // }, [MyActivities.isSuccess])

  console.log(MyActivities)
  console.log(status)

  const CeremonyType = new Map([
    [0, "Konfirmasjon"],
    [1, "Navnefest"],
    [2, "Begravelse"],
    [3, "Vigsel"],
  ])

  const statusType = new Map([
    [0, "Aktiv"],
    [1, "Inaktiv"],
    [2, "Slettet"],
  ])

  const paymentStatusType = new Map([
    [0, "Beløp ikke beregnet"],
    [1, "Ikke betalt"],
    [2, "Betalt noe"],
    [3, "Betalt hele beløpet"],
    [4, "Betalt for mye"],
    [5, "Betalingspåminnelse"],
    [6, "Purring"],
  ])

  const roleType = new Map([
    [0, "Ukjent"],
    [1, "Kontaktperson"],
    [2, "Andre"],
    [3, "Hovedperson"],
  ])

  const leaderType = new Map([
    [0, "Kursleder"],
    [1, "Vikar"],
  ])

  const answerType = new Map([
    [false, "Nei"],
    [true, "Ja"],
  ])

  useEffect(() => {
    if (
      orderItem.isSuccess &&
      orderItem.isRefetching == false &&
      orderItem?.data != null
    ) {
      setOrderItemReady(true)
    } else {
      orderItem.refetch()
    }
  }, [orderItem.isSuccess, orderItem.isRefetching])

  useEffect(() => {
    if (
      MyActivities.isSuccess &&
      MyActivities?.data != undefined &&
      orderItemReady == true &&
      MyActivities.isRefetching == false
    ) {
      setActivity(
        MyActivities.data?.[status]?.filter(item => {
          if (item.orderId == orderId && item.personId == personId) {
            return true
          } else {
            return false
          }
        })
      )
      setActivityReady(true)
    } else {
      MyActivities.refetch()
    }
  }, [MyActivities.isSuccess, orderItemReady, MyActivities.isRefetching])

  return (
    <div>
      {activityReady === false ? (
        <LoadingModal />
      ) : (
        <div className="bg-gray px-8 pb-16 lg:flex lg:justify-center lg:items-center">
          <Seo title={type === "ceremonies" ? "Seremoni" : "Kurs"} />
          <div className="lg:min-w-[50%] lg:max-w-[50%]">
            <div className="flex flex-col">
              <Link
                to="/"
                className="flex flex-row items-center font-sans text-blue text-sm mb-2 hover:text-orange-dark hover:cursor-pointer mt-2"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5 mr-2"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
                    clipRule="evenodd"
                  />
                </svg>
                Min side
              </Link>
              <span className="flex font-serif text-blue text-lg justify-center pb-2 border-b-2 border-blue">
                {type === "ceremonies" ? (
                  activity[0][type]?.dateAndTime !== undefined ? (
                    moment(activity[0][type]?.dateAndTime).format("DD.MM")
                  ) : (
                    activity[0][type]?.dateAndTimeDisplay
                      .charAt(0)
                      .toUpperCase() +
                    activity[0][type]?.dateAndTimeDisplay.slice(1)
                  )
                ) : activity[0][type]?.startCourseDayDate !== undefined ? (
                  moment(activity[0][type]?.startCourseDayDate).format(
                    "DD.MM"
                  ) +
                  " - " +
                  moment(activity[0][type]?.endCourseDayDate).format("DD.MM")
                ) : activity[0][type]?.startDate !== undefined ? (
                  <span>
                    {moment(activity[0][type]?.startDate).format("DD.MM") +
                      " - " +
                      moment(activity[0][type]?.endDate).format("DD.MM")}
                  </span>
                ) : (
                  activity[0][type]?.startDateDisplay.charAt(0).toUpperCase() +
                  activity[0][type]?.startDateDisplay.slice(1)
                )}
              </span>
            </div>
            <div className="border-b-2 border-blue">
              <div className="flex flex-col pt-4 pb-8 space-y-2">
                <span className="font-sans text-blue text-base">
                  {type === "ceremonies" ? "Seremoni" : "Kurs"}
                </span>
                <span className="font-sans text-blue text-lg font-bold">
                  {type === "ceremonies"
                    ? CeremonyType.get(activity[0][type]?.type)
                    : activity[0][type]?.category}{" "}
                  i{" "}
                  {type === "ceremonies"
                    ? activity[0][type]?.location
                    : activity[0][type]?.location}
                </span>
              </div>
              <div>
                {type === "ceremonies" &&
                status == "upcoming" &&
                CeremonyType.get(activity[0]?.[type]?.type) ==
                  "Konfirmasjon" ? (
                  <div>
                    <a
                      href={
                        "https://skjema.human.no/konfirmasjon/mine-konfirmanter/"
                      }
                      target="_blank"
                    >
                      <button className={GoToPageButton + right + " mr-4 "}>
                        Endre påmelding
                      </button>
                    </a>
                    {/* <a href={"https://dinmerkedag.no/"} target="_blank">
                      <button className={GoToPageButton + right}>
                        Din merkedag
                      </button>
                    </a> */}
                  </div>
                ) : null}
              </div>
            </div>
            <div className="flex flex-col space-y-2 border-b-2 border-blue mt-2 pb-2">
              <div className="flex flex-row space-x-4 items-center">
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6"
                    viewBox="0 0 27 32"
                    fill="none"
                  >
                    <path
                      d="M20.312 5.16V1.092C20.312 0.696 20.024 0.372 19.592 0.372H19.34C18.908 0.372 18.548 0.731999 18.548 1.092V5.16H9.224V1.092C9.224 0.731999 8.864 0.372 8.432 0.372H8.18C7.82 0.372 7.46 0.696 7.46 1.092V5.16H2.996C1.808 5.16 0.8 6.096 0.8 7.284V9.66H26.972V7.284C26.972 6.096 25.964 5.16 24.776 5.16H20.312ZM8.36 9.12C7.352 9.12 6.56 8.328 6.56 7.32C6.56 6.744 6.884 6.204 7.46 5.844V7.716C7.46 8.076 7.82 8.436 8.18 8.436H8.432C8.864 8.436 9.224 8.076 9.224 7.716V5.844C9.728 6.204 10.052 6.744 10.052 7.32C10.052 8.328 9.26 9.12 8.36 9.12ZM19.412 9.12C18.512 9.12 17.72 8.328 17.72 7.32C17.72 6.744 18.044 6.204 18.548 5.844V7.716C18.548 8.076 18.908 8.436 19.34 8.436H19.592C20.024 8.436 20.312 8.076 20.312 7.716V5.844C20.888 6.204 21.212 6.744 21.212 7.32C21.212 8.328 20.42 9.12 19.412 9.12ZM0.8 29.208C0.8 30.396 1.736 31.332 2.996 31.332H24.776C26.036 31.332 26.972 30.396 26.972 29.208V10.92H0.8V29.208ZM24.596 12.9V28.884H3.104V12.9H24.596ZM16.064 24.672L17.72 23.556C21.068 21.288 22.076 19.992 22.076 18.084C22.076 15.888 20.42 14.592 17.864 14.592C15.092 14.592 13.652 15.96 13.652 18.552H14.696C14.876 17.184 15.632 16.572 17 16.572C18.296 16.572 19.052 17.292 19.052 18.588C19.052 20.136 18.08 21.432 15.596 23.304L13.76 24.672V27.012H22.508V22.728H21.284C21.284 24.276 20.924 24.672 19.412 24.672H16.064ZM10.088 14.808H7.82L5.588 16.32V18.3L7.82 16.86V24.24C7.82 25.644 7.532 25.824 5.48 25.824V27.012H12.032V25.824C10.268 25.824 10.088 25.68 10.088 24.24V14.808Z"
                      fill="black"
                    />
                  </svg>
                </span>
                <span className="font-sans text-sm">
                  {type === "ceremonies" ? (
                    activity[0][type]?.dateAndTime !== undefined ? (
                      moment(activity[0][type]?.dateAndTime).format("DD.MM")
                    ) : (
                      activity[0][type]?.dateAndTimeDisplay
                        .charAt(0)
                        .toUpperCase() +
                      activity[0][type]?.dateAndTimeDisplay.slice(1)
                    )
                  ) : activity[0][type]?.startDate !== undefined ? (
                    <div className="flex flex-col">
                      <span>
                        {moment(activity[0][type]?.startDate).format("DD.MM") +
                          " - " +
                          moment(activity[0][type]?.endDate).format("DD.MM")}
                      </span>
                      <span>{"Ukedag: " + activity[0][type]?.weekDay}</span>
                    </div>
                  ) : (
                    activity[0][type]?.startDateDisplay
                      .charAt(0)
                      .toUpperCase() +
                    activity[0][type]?.startDateDisplay.slice(1)
                  )}
                </span>
              </div>
              <div className="flex flex-row space-x-4 items-center">
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6"
                    viewBox="0 0 20 30"
                    fill="none"
                  >
                    <path
                      d="M10.268 0.107999C15.992 0.107999 19.736 3.996 19.736 9.072C19.736 11.052 19.16 13.104 18.008 15.228L10.268 29.412L2.528 15.228C1.376 13.104 0.8 11.052 0.8 9.072C0.8 3.996 4.544 0.107999 10.268 0.107999ZM10.232 14.04C13.148 14.04 15.416 11.88 15.416 8.892C15.416 5.868 13.148 3.636 10.232 3.636C7.28 3.636 5.048 5.868 5.048 8.892C5.048 11.88 7.28 14.04 10.232 14.04Z"
                      fill="black"
                    />
                  </svg>
                </span>
                <a
                  href={`https://www.google.com/maps/search/${activity[0][type]?.location}`}
                  target="_blank"
                  className={underlineButton}
                >
                  {type === "ceremonies"
                    ? activity[0][type]?.location
                    : activity[0][type]?.location}
                </a>
              </div>
              <div className="flex flex-row space-x-4 items-center">
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-12"
                    viewBox="0 0 20 48"
                    fill="none"
                  >
                    <path
                      d="M14.4843 23.1407C14.4244 19.8225 14.359 16.0551 14.1082 10.3451C14.1082 9.84543 14.0047 9.59277 13.8629 9.59277C13.7212 9.59277 13.6122 9.97457 13.6122 10.3451C13.4323 20.3671 11.4319 22.4726 9.79667 22.4726C7.48555 22.4726 6.24277 18.4133 6.09015 10.4013C6.09015 10.4013 6.09015 9.59838 6.09015 8.68882V8.08246C6.09015 6.15105 6.09015 5.75802 6.09015 5.75241C6.09015 5.38184 6.01384 5.00567 5.82852 5.00006C5.64319 4.99444 5.57778 5.40992 5.56143 5.75241C5.56143 5.75241 5.39791 10.3732 5.39791 10.4013C5.15807 15.853 5.10356 19.5081 5.05451 22.7533V22.8151C5.05451 23.865 5.0218 24.8532 5 25.8245C5 26.6386 5.0327 27.4696 5.04906 28.3454V28.4633C5.10902 31.7872 5.17442 35.5545 5.42516 41.2758C5.42516 41.7755 5.52872 42.0225 5.67589 42.0225C5.82306 42.0225 5.92118 41.6463 5.92118 41.2702C6.10105 31.2482 8.10148 29.1483 9.73671 29.1483C11.3719 29.1483 13.3942 31.2482 13.5522 41.2702C13.5522 41.6463 13.6286 42.0225 13.803 42.0225C13.9447 42.0225 14.0265 41.7755 14.0483 41.2758C14.299 35.5602 14.3644 31.7984 14.4244 28.4746C14.4244 27.5482 14.4571 26.6835 14.4734 25.8245C14.4898 24.9655 14.5007 24.0615 14.4843 23.1407ZM9.75851 20.2941C10.7942 20.2941 11.6336 19.1712 11.6336 17.7676C11.6336 16.3639 10.7942 15.241 9.75851 15.241C8.72287 15.241 7.878 16.3639 7.878 17.7676C7.878 19.1712 8.74467 20.2941 9.75851 20.2941Z"
                      fill="#0F0F14"
                    />
                  </svg>
                </span>
                <div className="flex flex-col">
                  {orderItem?.data?.numberOfGuestSeastsUsed !== undefined ? (
                    <span className="font-sans text-sm">
                      {orderItem?.data?.numberOfGuestSeastsUsed} gjester
                    </span>
                  ) : null}
                  {orderItem?.data?.sentGrantedNrOfGuestSeats !== undefined ? (
                    <span className="font-sans text-sm">
                      {orderItem?.data?.sentGrantedNrOfGuestSeats} gjesteplasser
                    </span>
                  ) : null}
                  {orderItem?.data?.preferredNrOfGuestSeats !== undefined ? (
                    <span className="font-sans text-sm">
                      {orderItem?.data?.preferredNrOfGuestSeats} ønskede
                      gjesteplasser
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="flex flex-col space-y-6 py-2">
              {/* Status */}
              <label className="font-sans text-base text-blue">Status</label>
              <div className="flex flex-col space-y-2 bg-white py-2 px-4">
                <span className="font-sans text-sm text-blue">
                  <span className="font-bold">Status:</span>{" "}
                  {statusType.get(orderItem?.data?.status)}
                </span>
                <span className="font-sans text-sm text-blue">
                  <span className="font-bold">Betalingsstatus:</span>{" "}
                  {paymentStatusType.get(orderItem?.data?.paymentStatus)}
                </span>
                <span className="font-sans text-sm text-blue">
                  <span className="font-bold">Din rolle:</span>{" "}
                  {roleType.get(orderItem?.data?.role)}
                </span>
                <span className="font-sans text-sm text-blue">
                  <span className="font-bold">Ordrenummer:</span>{" "}
                  {orderItem?.data?.orderNumber}
                </span>
                <span className="font-sans text-sm text-blue">
                  <span className="font-bold">Ordredato:</span>{" "}
                  {moment(orderItem?.data?.orderDateTime).format("DD.MM.YY")}
                </span>
              </div>
              {/* Additional Activity */}
              {type === "courses" ? (
                <>
                  {activity[0][type]?.additionalActivity.length > 0 ? (
                    <div className="flex flex-col space-y-6 py-2">
                      <label className="font-sans text-base text-blue">
                        Tilleggsaktiviteter
                      </label>
                      <div className="flex flex-col space-y-2 bg-white py-2 px-4">
                        <span className="font-bold font-sans text-sm text-blue">
                          Informasjon
                        </span>
                        <span className="font-sans text-sm text-blue">
                          {activity[0][type]?.additionalActivity}
                        </span>
                      </div>
                    </div>
                  ) : null}
                </>
              ) : null}
              {/* Canceled Activity */}
              {type === "courses" ? (
                <>
                  {activity[0][type]?.canceledActivity.length > 0 ? (
                    <div className="flex flex-col space-y-6 py-2">
                      <label className="font-sans text-base text-blue">
                        Kansellerte aktiviteter
                      </label>
                      <div className="flex flex-col space-y-2 bg-white py-2 px-4">
                        <span className="font-bold font-sans text-sm text-blue">
                          Informasjon
                        </span>
                        <span className="font-sans text-sm text-blue">
                          {activity[0][type]?.canceledActivity}
                        </span>
                      </div>
                    </div>
                  ) : null}
                </>
              ) : null}
              {/* Consents */}
              <label className="font-sans text-base text-blue pt-2">
                Samtykke
              </label>
              {orderItem?.data?.consents?.length !== 0 ? (
                orderItem?.data?.consents?.map((items, i) => (
                  <div
                    key={i}
                    className="flex flex-col space-y-2 bg-white py-2 px-4"
                  >
                    <div className="flex flex-col space-y-2">
                      <span className="font-sans text-sm text-blue font-bold">
                        {items.title}
                      </span>
                      <span className="font-sans text-sm text-blue">
                        {items.text}
                      </span>
                      <span className="font-sans text-sm text-blue pt-4">
                        <span className="font-bold">Svar på samstykke:</span>{" "}
                        {answerType.get(items.answer)}
                      </span>
                    </div>
                  </div>
                ))
              ) : (
                <div className="bg-white py-2 px-4">
                  <span className="font-sans text-sm text-blue">
                    Du har ingen samtykker
                  </span>
                </div>
              )}
              {/* People */}
              <label className="font-sans text-base text-blue pt-2">
                Kontaktinformasjon
              </label>
              {orderItem?.data?.people?.length !== 0 ? (
                orderItem?.data?.people?.map((items, i) => (
                  <div
                    key={i}
                    className="flex flex-col space-y-2 bg-white py-2 px-4"
                  >
                    <div className="flex flex-col space-y-2">
                      <span className="font-sans text-sm text-blue font-bold">
                        {items.firstName} {items.surname}
                      </span>
                      {items.mobile !== undefined ? (
                        <span className="font-sans text-sm text-blue">
                          Mobil: {items.mobile}
                        </span>
                      ) : null}
                      {items.email !== undefined ? (
                        <span className="font-sans text-sm text-blue">
                          Epost: {items.email}
                        </span>
                      ) : null}
                      {items.role !== undefined ? (
                        <span className="font-sans text-sm text-blue">
                          Rolle: {roleType.get(items.role)}
                        </span>
                      ) : null}
                    </div>
                  </div>
                ))
              ) : (
                <div className="bg-white py-2 px-4">
                  <span className="font-sans text-sm text-blue">
                    Det er ingen kontaktpersoner registrert
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

const ActivitiesInfo = () => {
  const authRequest: RedirectRequest = {
    ...loginRequest,
  }

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      // loadingComponent={LoadingModal}
    >
      <QueryClientProvider client={queryClient}>
        <ActivitiesContent />
      </QueryClientProvider>
    </MsalAuthenticationTemplate>
  )
}

export default ActivitiesInfo
